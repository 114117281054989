import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import { FaWhatsapp } from 'react-icons/fa';
import '../Assets/Styles/FloatingIcons.css'
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

const Movingicon = () => {
    const [socialLinks, setSocialLinks] = useState({});
    // console.log("socialLinks", socialLinks);

    useEffect(() => {
        // Fetching social media links
        axios
            .get("/Social/get")
            .then((response) => {
                setSocialLinks(response.data.responseData[0]);
            })
            .catch((error) => {
                console.error("There was an error fetching the social media links!", error);
            });
    }, []);

    const [modalOpen, setModalOpen] = useState(false);

    // const [modalOpen1, setModalOpen1] = useState(false);
    const [errors, setErrors] = useState({});
    const [captchaValue, setCaptchaValue] = useState(null);
    const [msg, setMessage] = useState("");
    const recaptchaRef = useRef(null);

    const [company, setCompany] = useState("");
    const [designation, setDesignation] = useState("");
    const [business, setBusiness] = useState("");
    const [phoneno, setPhones] = useState("");
    // const [date, setDate] = useState("");
    const [contact_person, setContactPerson] = useState("");
    const [email_id, setEmailId] = useState("");
    const [machine_type, setMachineType] = useState("");
    const [machine_manufacturer, setMachineManufacturer] = useState("");
    const [machine_weight, setMachineWeight] = useState("");
    const [machine_tool_weight, setMachineToolWeight] = useState("");
    const [mounting_position, setMountingPosition] = useState("");
    const [mounting_hole_diameter, setMountingHoleDiameter] = useState("");
    const [mounting_foot, setMountingFoot] = useState("");
    const [speed_of_machine, setSpeedOfMachine] = useState("");
    const [machine_stokes, setMachineStokes] = useState("");
    const [machine_feed_rate, setMachineFeedRate] = useState("");
    const [current_location, setCurrentlocation] = useState("");
    // const [meassage, setmeassage] = useState("");

    const handleConnectNow = async (id) => {
        setModalOpen(true);
    };

    const handleInputChange = (setter) => (event) => {
        setter(event.target.value);
        if (errors.msg && event.target.name === "msg") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                msg: '',
            }));
        }
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        if (value) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                captcha: '',
            }));
        }
    };

    const validateForm3 = () => {
        let errors = {};
        let isValid = true;

        if (!company.trim()) {
            errors.company = "Company is Required";
            isValid = false;
        }
        if (!designation.trim()) {
            errors.designation = "Designation is Required";
            isValid = false;
        }
        if (!business.trim()) {
            errors.business = "Business is Required";
            isValid = false;
        }
        if (!phoneno.trim()) {
            errors.phoneno = "Phone No. is Required";
            isValid = false;
        } else if (!/^\d{10}$/.test(phoneno)) {
            errors.phoneno = "Phone number must be exactly 10 digits";
            isValid = false;
        }
        // if (!date.trim()) {
        //     errors.date = "Date is Required";
        //     isValid = false;
        // }
        if (!contact_person.trim()) {
            errors.contact_person = "Contact Person is Required";
            isValid = false;
        }
        if (!email_id.trim()) {
            errors.email_id = "Email Id is Required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email_id)) {
            errors.email_id = "Invalid Email Id address";
            isValid = false;
        }
        if (!machine_type.trim()) {
            errors.machine_type = "Machine Type is Required";
            isValid = false;
        }
        if (!machine_manufacturer.trim()) {
            errors.machine_manufacturer = "Machine Manufacturer is Required";
            isValid = false;
        }
        if (!machine_weight.trim()) {
            errors.machine_weight = "Machine Weight is Required";
            isValid = false;
        }
        if (!machine_tool_weight.trim()) {
            errors.machine_tool_weight = "Machine Tool Weight is Required";
            isValid = false;
        }
        if (!mounting_position.trim()) {
            errors.mounting_position = "Mounting Position is Required";
            isValid = false;
        }
        if (!mounting_hole_diameter.trim()) {
            errors.mounting_hole_diameter = "Mounting Hole Diameter is Required";
            isValid = false;
        }
        if (!mounting_foot.trim()) {
            errors.mounting_foot = "Mounting Foot is Required";
            isValid = false;
        }
        if (!speed_of_machine.trim()) {
            errors.speed_of_machine = "Speed Of Machine is Required";
            isValid = false;
        }
        if (!machine_stokes.trim()) {
            errors.machine_stokes = "Machine Stokes is Required";
            isValid = false;
        }
        if (!machine_feed_rate.trim()) {
            errors.machine_feed_rate = "Machine Feed Rate is Required";
            isValid = false;
        }
        if (!current_location.trim()) {
            errors.current_location = "Current Location is Required";
            isValid = false;
        }
        if (!captchaValue) {
            errors.captcha = 'Please complete the CAPTCHA';
            isValid = false;
        }
        setErrors(errors);
        return isValid;
    };
    const handleSubmit3 = async (e) => {
        e.preventDefault();
        if (validateForm3()) {
            try {
                const response = await axios.post("EnquirySheet/add", {
                    company, designation, business, phoneno, contact_person, email_id, machine_type, machine_manufacturer, machine_weight,
                    machine_tool_weight, mounting_position, mounting_hole_diameter, mounting_foot, speed_of_machine, machine_stokes, machine_feed_rate, current_location,
                });

                if (!captchaValue) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        captcha: 'Please complete the CAPTCHA',
                    }));
                } else {
                    // Handle form submission
                    console.log('Form submitted:', { msg });

                    // Clear CAPTCHA and reset state after submission
                    recaptchaRef.current.reset();
                    setCaptchaValue('');
                    setMessage('');
                    setErrors({});
                }

                if (response.status === 200) {
                    // Reset form fields and state after successful submission
                    setCompany("");
                    setDesignation("");
                    setBusiness("");
                    setPhones("");
                    setContactPerson("");
                    setEmailId("");
                    setMachineType("");
                    setMachineManufacturer("");
                    setMachineWeight("");
                    setMachineToolWeight("");
                    setMountingPosition("");
                    setMountingHoleDiameter("");
                    setMountingFoot("");
                    setSpeedOfMachine("");
                    setMachineStokes("");
                    setMachineFeedRate("");
                    setCurrentlocation("");
                    setMessage("");
                    setErrors({});
                    setCaptchaValue(null); // Clear captcha value after successful submission
                    alert("Thank you, we will connect with you soon");
                }
            } catch (error) {
                console.error("Error submitting data:", error);
                const newErrors = { ...errors };

                // Check if the error is a validation error for mobile number or email
                if (error.response?.data?.message === "Validation error: Mobile number already exists.") {
                    newErrors.phone = "Phone number already exists.";
                } else if (error.response?.data?.message === "Validation error: Email already exists.") {
                    newErrors.email = "Email already exists.";
                } else {
                    newErrors.general = "Failed to submit data. Please try again later.";
                }

                setErrors(newErrors);
            }
        }
    };
    return (
        <>
            <div className="floating-icons">

                <div>
                    {socialLinks && (<a href={`https://wa.me/${socialLinks.whatsapp}`} target='_blank' aria-label="Bilz" className="whatsapp">
                        <FaWhatsapp className="iconz whatsapp" />
                    </a>)}
                </div>

                <div className="quick-enquiry">
                    <a href="#enquiry-form" className="quick-enquiry-text" rel="noopener noreferrer" onClick={() => handleConnectNow()} >
                        Quick Enquiry
                    </a>
                </div>

            </div>
            <Modal show={modalOpen} centered onHide={() => setModalOpen(false)} size="lg"
                aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Header closeButton className='bg-info modalform'>
                    <Modal.Title id="example-modal-sizes-title-lg" className='text-white'>Enquiry Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit3}>
                        <Row>
                            <h4 className='text-center fw-bold'>CUSTOMER DETAILES</h4>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Company : </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="company"
                                        className='rounded-4'
                                        value={company}
                                        onChange={handleInputChange(setCompany)}
                                    />
                                    {errors.company && <span className="error ms-2 fw-light text-danger">{errors.company}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Designation : </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="designation"
                                        className='rounded-4'
                                        value={designation}
                                        onChange={handleInputChange(setDesignation)}
                                    />
                                    {errors.designation && <span className="error ms-2 fw-light text-danger">{errors.designation}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Business : </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="business"
                                        className='rounded-4'
                                        value={business}
                                        onChange={handleInputChange(setBusiness)}
                                    />
                                    {errors.business && <span className="error ms-2 fw-light text-danger">{errors.business}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Phone No. : </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone_no"
                                        className='rounded-4'
                                        minLength={10}
                                        maxLength={10}
                                        value={phoneno}
                                        onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                                        onChange={handleInputChange(setPhones)}
                                    />
                                    {errors.phoneno && <span className="error ms-2 fw-light text-danger">{errors.phoneno}</span>}
                                </Form.Group>
                            </Col>
                            {/* <Col xs={12} md={3}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Date : </Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        className='rounded-4'
                                        minLength={10}
                                        maxLength={10}
                                        value={date}
                                        onChange={handleInputChange(setDate)}
                                    />
                                    {errors.date && <span className="error ms-2 fw-light text-danger">{errors.date}</span>}
                                </Form.Group>
                            </Col> */}
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Contact Person : </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="contact_person"
                                        className='rounded-4'
                                        value={contact_person}
                                        onChange={handleInputChange(setContactPerson)}
                                    />
                                    {errors.contact_person && <span className="error ms-2 fw-light text-danger">{errors.contact_person}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Email Id : </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email_id"
                                        className='rounded-4'
                                        value={email_id}
                                        onChange={handleInputChange(setEmailId)}
                                    />
                                    {errors.email_id && <span className="error ms-2 fw-light text-danger">{errors.email_id}</span>}
                                </Form.Group>
                            </Col>
                            <h4 className='text-center fw-bold'>MACHINE DETAILES</h4>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Machine Type : </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="machine_type"
                                        className='rounded-4'
                                        value={machine_type}
                                        onChange={handleInputChange(setMachineType)}
                                    />
                                    {errors.machine_type && <span className="error ms-2 fw-light text-danger">{errors.machine_type}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Machine Manufacturer : </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="machine_manufacturer"
                                        className='rounded-4'
                                        value={machine_manufacturer}
                                        onChange={handleInputChange(setMachineManufacturer)}
                                    />
                                    {errors.machine_manufacturer && <span className="error ms-2 fw-light text-danger">{errors.machine_manufacturer}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Machine Weight : (kg.)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="machine_weight"
                                        className='rounded-4'
                                        value={machine_weight}
                                        onChange={handleInputChange(setMachineWeight)}
                                    />
                                    {errors.machine_weight && <span className="error ms-2 fw-light text-danger">{errors.machine_weight}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Machine Tool Weight : (kg.) </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="machine_tool_weight"
                                        className='rounded-4'
                                        value={machine_tool_weight}
                                        onChange={handleInputChange(setMachineToolWeight)}
                                    />
                                    {errors.machine_tool_weight && <span className="error ms-2 fw-light text-danger">{errors.machine_tool_weight}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>No Of Mounting Position : (Nos.) </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="mounting_position"
                                        className='rounded-4'
                                        value={mounting_position}
                                        onChange={handleInputChange(setMountingPosition)}
                                    />
                                    {errors.mounting_position && <span className="error ms-2 fw-light text-danger">{errors.mounting_position}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Mounting Hole Diameter : (mm.) </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="mounting_hole_diameter"
                                        className='rounded-4'
                                        value={mounting_hole_diameter}
                                        onChange={handleInputChange(setMountingHoleDiameter)}
                                    />
                                    {errors.mounting_hole_diameter && <span className="error ms-2 fw-light text-danger">{errors.mounting_hole_diameter}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Size Of Mounting Foot : (LXWmm) </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="mounting_foot"
                                        className='rounded-4'
                                        value={mounting_foot}
                                        onChange={handleInputChange(setMountingFoot)}
                                    />
                                    {errors.mounting_foot && <span className="error ms-2 fw-light text-danger">{errors.mounting_foot}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Speed Of Machine : (RPM) </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="speed_of_machine"
                                        className='rounded-4'
                                        value={speed_of_machine}
                                        onChange={handleInputChange(setSpeedOfMachine)}
                                    />
                                    {errors.speed_of_machine && <span className="error ms-2 fw-light text-danger">{errors.speed_of_machine}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Machine Stokes : (SPM) </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="machine_stokes"
                                        className='rounded-4'
                                        value={machine_stokes}
                                        onChange={handleInputChange(setMachineStokes)}
                                    />
                                    {errors.machine_stokes && <span className="error ms-2 fw-light text-danger">{errors.machine_stokes}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Machine Feed Rate : (M/min) </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="machine_feed_rate"
                                        className='rounded-4'
                                        value={machine_feed_rate}
                                        onChange={handleInputChange(setMachineFeedRate)}
                                    />
                                    {errors.machine_feed_rate && <span className="error ms-2 fw-light text-danger">{errors.machine_feed_rate}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Current location (Ground Floor,Basement, 1st floor, etc.) : </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="current_location"
                                        className='rounded-4'
                                        value={current_location}
                                        onChange={handleInputChange(setCurrentlocation)}
                                    />
                                    {errors.current_location && <span className="error ms-2 fw-light text-danger">{errors.current_location}</span>}
                                </Form.Group>
                            </Col>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LdKjlwqAAAAAMM4PV64krFdtNiannAhVkCmzXbH"
                                onChange={handleCaptchaChange}
                            />
                            {errors.captcha && <span className="error ms-2 fw-light text-danger">{errors.captcha}</span>}
                            <div className="text-center">
                                <Button
                                    variant="success"
                                    type="submit"
                                    style={{ background: '#82B547', border: 'none' }}
                                    className="my-4"
                                >
                                    Submit
                                </Button>
                            </div>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};


export default Movingicon
